import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeService, StripeCardComponent, ElementOptions, ElementsOptions } from 'ngx-stripe';
import { takeUntil } from 'rxjs/operators';
import { CustomerAccountClientService } from '../../service/customer-account-client.service';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomerAccount } from '../../shared/model/customer-account';
import { CrossFieldErrorMatcher } from './cross-field-error.matcher';

@Component({
  selector: 'app-customer-account',
  templateUrl: './customer-account.component.html',
  styleUrls: ['./customer-account.component.css']
})
export class CustomerAccountComponent extends BioVerifyComponent implements OnInit {

  @ViewChild(StripeCardComponent, {static: false}) card: StripeCardComponent;
  customerAccountForm: FormGroup;
  isSaving = false;
  errorMatcher = new CrossFieldErrorMatcher();
  paymentMethod = 'card';

  get disabled(): boolean {
    return this.customerAccountForm.get('checked').value === false;
  }

  formErrors = {
    'companyName': '',
    'invoicingAddress': '',
    'contactEmailAddress': '',
    'pdfPassword': '',
    'verifyPassword': '',
    'nameOnPaymentCard': '',
    'checked': '',
  };

  validationMessages = {
    'companyName': {
      'required':      'Please provide a company name'
    },
    'invoicingAddress': {
      'required':      'Please provide an address to appear on invoices',
      'email':      'Please provide a valid email address'
    },
    'contactEmailAddress': {
      'required':      'Please provide an address for users with questions to contact',
      'email':      'Please provide a valid email address'
    },
    'pdfPassword': {
      'required':   'A password is required to protect your PDF reports',
      'minlength':   'Passwords must be at least 12 characters long',
    },
    'verifyPassword': {
      'required':   'You must ret-enter your password to verify',
    },
    'nameOnPaymentCard': {
      'required':   'Please provide this information',
    },
    'checked': {
      'required':      'Please provide this information'
    }
  };

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        // fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#DFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private stripeService: StripeService,
    private customerAccountClientService: CustomerAccountClientService
  ) {
    super();
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.customerAccountForm = this.fb.group({
      'companyName': ['', [Validators.required]],
      'invoicingAddress': ['', [Validators.required, Validators.email]],
      'contactEmailAddress': ['', [Validators.required, Validators.email]],
      'pdfPassword': ['', [Validators.required, Validators.minLength(12)]],
      'verifyPassword': ['', [Validators.required]],
      'billingType': ['card', [Validators.required]],
      'nameOnPaymentCard': ['', [Validators.required]],
      'checked': [false, [Validators.required]],
    }, {
      validator: this.passwordValidator
    });

    this.customerAccountForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.showValidationErrors(false);
      if (data.billingType && data.billingType !== this.paymentMethod) {
        console.log(`BillingType set to ${data.billingType}`);
        this.paymentMethod = data.billingType;
        this.customerAccountForm.get('nameOnPaymentCard').setValidators(this.paymentMethod === 'card' ? [Validators.required] : []);
        this.customerAccountForm.get('nameOnPaymentCard').updateValueAndValidity();
      }
    });

  }

  showValidationErrors(ignoreDirty: Boolean) {
    this.showErrors(this.customerAccountForm, this.formErrors, this.validationMessages, ignoreDirty);
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('pdfPassword').value !== form.get('verifyPassword').value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }

  register() {
    this.showValidationErrors(true);
    if (this.customerAccountForm.valid === false) {
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.showProgressSpinner(this.dialog);
    this.getCustomerAccount(this.customerAccountForm).then(customerAccount => {
      console.log(`Registering ${JSON.stringify(customerAccount)}`);
      return this.customerAccountClientService.register(customerAccount);
    }).then(() => {
      console.log(`Registered successfully`);
      this.isSaving = false;
      this.hideProgressSpinner();
      this.router.navigate(['/securehome']);
    }).catch(err => {
      console.log(`Send failed: ${err}`);
      this.isSaving = false;
      this.hideProgressSpinner();
      if (err.error && err.error.errorMessage && (err.error.errorMessage.startsWith('[400] ') || err.error.errorMessage.startsWith('[401] '))) {
        this.showAlert(this.dialog, 'PROBLEM', `Your registration could not be saved - ${err.error.errorMessage.substr(5)}`);
      } else {
        this.showAlert(this.dialog, 'PROBLEM', 'Your registration could not be saved - please try again later');
      }
    });
  }

  async getCustomerAccount(form: FormGroup): Promise<CustomerAccount> {
    const account: CustomerAccount = {
      companyName: form.value.companyName,
      invoicingAddress: form.value.invoicingAddress,
      contactEmailAddress: form.value.contactEmailAddress,
      pdfPassword: form.value.pdfPassword,
      nameOnPaymentCard: form.value.nameOnPaymentCard,
      termsAccepted: form.value.checked,
      billingType: form.value.billingType,
      primaryVerificationType: 'NZ_STANDARD_BIOMETRIC',
      biometricProvider: 'ocrlabs',
      defaultResultDeliveryMethod: 'PDF_VIA_EMAIL',
    };
    if (account.billingType === 'card') {
      const name = account.nameOnPaymentCard;
      const tokenResult = await this.stripeService.createToken(this.card.getCard(), { name }).toPromise();
      account.paymentToken = tokenResult.token.id;
    }
    return account;
  }

}
