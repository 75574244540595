import { Component, OnInit } from '@angular/core';
import { BrandingService } from 'src/app/service/branding.service';

@Component({
  selector: 'app-capture-cancelled',
  templateUrl: './capture-cancelled.component.html',
  styleUrls: ['./capture-cancelled.component.css']
})
export class CaptureCancelledComponent {

  get logoUrl(): string {
    return this.brandingService.logoUrl;
  }

  constructor(
    private brandingService: BrandingService,
  ) { }

}
