import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as showdown from 'showdown';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent {

  get content(): string {
    if (this.md) {
      return this.converter.makeHtml(this.md);
    }
    return '';
  }

  private md = '';
  private converter: any;

  constructor(
    private http: HttpClient,
  ) {
    this.converter = new showdown.Converter({openLinksInNewWindow: true});
    this.load();
   }

  async load() {
    const md = await this.http.get('/assets/docs/api.md', { responseType: 'text' }).toPromise();
    this.md = md;

  }

}
