import { DocumentType } from './id-document';
import { VerificationResult, DocumentUpload, VerificationStatus, DataSourceField, DataSourceCheck, WatchListCheck } from './verification-result';
import { SimpleDate } from './simple-date';
import { DataSourceCheckState } from 'functions/src/model/verification-result';

export class ExtendedVerificationResult implements VerificationResult {

  private result: VerificationResult;

  constructor(verificationResult: VerificationResult) {
    this.result = verificationResult;
  }

  get verificationStatus(): VerificationStatus {
    return this.result.verificationStatus || 'FAILED';
  }

  get verificationId(): string {
    return this.result.verificationId || '';
  }

  get dateCreated(): string {
    return this.result.dateCreated || '';
  }

  get firstName(): string {
    return this.result.individual.firstName || '';
  }

  get middleNames(): string {
    return this.result.individual.middleNames || '';
  }

  get lastName(): string {
    return this.result.individual.lastName || '';
  }

  get dateOfBirth(): SimpleDate {
    return this.result.individual.dateOfBirth;
  }

  get unitNumber(): string {
    return this.result.individual.residentialAddress.unitNumber || '';
  }

  get streetNumber(): string {
    return this.result.individual.residentialAddress.streetNumber || '';
  }

  get streetName(): string {
    return this.result.individual.residentialAddress.streetName || '';
  }

  get streetType(): string {
    return this.result.individual.residentialAddress.streetType || '';
  }

  get suburb(): string {
    return this.result.individual.residentialAddress.suburb || '';
  }

  get townCity(): string {
    return this.result.individual.residentialAddress.city || '';
  }

  get postcode(): string {
    return this.result.individual.residentialAddress.postcode || '';
  }

  get country(): string {
    return this.result.individual.residentialAddress.country || '';
  }

  get documentType(): DocumentType {
    return this.result.idDocument.documentType;
  }

  get documentAuthenticity(): string {
    return this.result.documentAuthenticity || '';
  }

  get documentNumber(): string {
    return this.result.idDocument.documentNumber || '';
  }

  get secondaryDocumentNumber(): string {
    return this.result.idDocument.secondaryDocumentNumber || '';
  }

  get documentExpiryDate(): SimpleDate {
    return this.result.idDocument.documentExpiryDate;
  }

  get documentExpiryDateString(): string {
    return this.dateToString(this.result.idDocument.documentExpiryDate);
  }

  get documentDataSourceMatchStatus(): DataSourceCheckState {
    return this.result.documentDataSourceMatchStatus || 'ERROR';
  }

  get documentDataSourceMatchFields(): DataSourceField[] {
    return this.result.documentDataSourceMatchFields;
  }

  get documentFrontCaptureMethod(): string {
    return this.result.documentFrontCaptureMethod || '';
  }

  get documentBackCaptureMethod(): string {
    return this.result.documentBackCaptureMethod || '';
  }

  get documentFrontImagePath(): string {
    if (this.result.verificationImages) {
      return this.result.verificationImages.documentFrontImagePath || '';
    }
    return '';
  }

  get documentBackImagePath(): string {
    if (this.result.verificationImages) {
      return this.result.verificationImages.documentBackImagePath || '';
    }
    return '';
  }

  get documentPhotoPath(): string {
    if (this.result.verificationImages) {
      return this.result.verificationImages.documentPhotoPath || '';
    }
    return '';
  }

  get selfieImagePath(): string {
    if (this.result.verificationImages) {
      return this.result.verificationImages.selfieImagePath || '';
    }
    return '';
  }

  get documentUpload(): DocumentUpload {
    return this.result.documentUpload;
  }

  get faceMatchScore(): string {
    return this.result.faceMatchScore || '';
  }

  get faceMatchState(): string {
    return this.result.faceMatchState || '';
  }

  get faceCaptureMethod(): string {
    return this.result.faceCaptureMethod || '';
  }

  get livenessState(): string {
    return this.result.livenessState || '';
  }

  get dataExtractionState(): string {
    return this.result.dataExtractionState || '';
  }

  // Extensions

  get isVerified(): boolean {
    return this.result.verificationStatus === 'VERIFIED';
  }

  get fullName(): string {
    if (this.middleNames) {
      return `${this.firstName} ${this.middleNames} ${this.lastName}`;
    } else {
      return `${this.firstName} ${this.lastName}`;
    }
  }
  get dateOfBirthDisplayText(): string {
    return this.dateToString(this.result.individual.dateOfBirth);
  }
  get fullAddress(): string {
    if (this.unitNumber) {
      return `${this.unitNumber} ${this.streetNumber} ${this.streetName} ${this.streetType} ${this.suburb} ${this.townCity} ${this.postcode}  ${this.country}`;
    } else {
      return `${this.streetNumber} ${this.streetName} ${this.streetType} ${this.suburb} ${this.townCity} ${this.postcode}  ${this.country}`;
    }
  }

  get faceMatchScoreNumber(): number {
    if (this.result.faceMatchScore) {
      return Number(this.result.faceMatchScore || '0');
    }
    return 0;
  }

  get biometricMatchState(): string {
    const faceMatch = this.faceMatchState.toUpperCase();
    const livenessState = this.livenessState.toUpperCase();
    if (faceMatch === 'VERIFIED' && livenessState === 'VERIFIED') {
      return 'verified';
    } else if ((faceMatch === 'VERIFIED' || faceMatch === 'PENDING') &&
      (livenessState === 'VERIFIED' || livenessState === 'PENDING')) {
      return 'pending';
    } else {
      return 'failed';
    }
  }

  get documentTypeDescription(): string {
    switch (this.documentType) {
      case 'DRIVERS_LICENCE.NZ':
      case 'DRIVERS_LICENCE.AU-ACT':
      case 'DRIVERS_LICENCE.AU-NSW':
      case 'DRIVERS_LICENCE.AU-NT':
      case 'DRIVERS_LICENCE.AU-QLD':
      case 'DRIVERS_LICENCE.AU-SA':
      case 'DRIVERS_LICENCE.AU-TAS':
      case 'DRIVERS_LICENCE.AU-VIC':
      case 'DRIVERS_LICENCE.AU-WA':
      case 'DRIVERS_LICENCE.UK':
        return `Driver's Licence`;
      case 'PASSPORT.NZ':
      case 'PASSPORT.AU':
      case 'PASSPORT.UK':
      case 'PASSPORT.INTL':
        return `Passport`;

      default:
        return `ID`;
    }
  }

  get isDocumentBackAvailable(): boolean {
    if (this.documentBackImagePath) {
      return true;
    }
    return false;
  }

  get allDataSources(): DataSourceCheck[] {
    return this.result.dataSourceChecks;
  }

  get matchedDataSources(): DataSourceCheck[] {
    return this.result.dataSourceChecks.filter(check => check.state === 'MATCH' || check.state === 'PARTIAL_MATCH');
  }

  get unmatchedDataSources(): DataSourceCheck[] {
    return this.result.dataSourceChecks.filter(check => check.state === 'NO_MATCH');
  }

  get failedDataSources(): DataSourceCheck[] {
    return this.result.dataSourceChecks.filter(check => check.state === 'ERROR');
  }

  get matchedWatchLists(): WatchListCheck[] {
    return this.result.watchListChecks.filter(check => check.state === 'MATCH' || check.state === 'POSSIBLE_MATCH');
  }

  get unmatchedWatchLists(): WatchListCheck[] {
    return this.result.watchListChecks.filter(check => check.state === 'NO_MATCH' ).sort((a, b) => a.name.localeCompare(b.name));
  }

  get watchListState(): string {
    if (this.result.watchListChecks.length === 0) {
      return 'NOT_CHECKED';
    }
    return this.matchedWatchLists.length > 0 ? 'PENDING' : 'VERIFIED';
  }

  get showCompactWatchLists(): boolean {
    return this.result.watchListChecks.length > 100;
  }

  get extractedData(): ExtractedData[] {
    const array: ExtractedData[] = [];
    array.push({
      label: 'FIRST NAME',
      extractedValue: this.getExtractedDisplayValue(this.result.individual.firstName, this.result.firstNameExtracted, this.result.firstNameChanged),
      changedValue: this.getChangedDisplayValue(this.result.firstNameChanged),
    });
    array.push({
      label: 'MIDDLE NAME(S)',
      extractedValue: this.getExtractedDisplayValue(this.result.individual.middleNames, this.result.middleNamesExtracted, this.result.middleNamesChanged),
      changedValue: this.getChangedDisplayValue(this.result.middleNamesChanged),
    });
    array.push({
      label: 'LAST NAME',
      extractedValue: this.getExtractedDisplayValue(this.result.individual.lastName, this.result.lastNameExtracted, this.result.lastNameChanged),
      changedValue: this.getChangedDisplayValue(this.result.lastNameChanged),
    });
    array.push({
      label: 'DATE OF BIRTH',
      extractedValue: this.getExtractedDisplayValue(this.dateOfBirthDisplayText, this.result.dateOfBirthExtracted, this.result.dateOfBirthChanged),
      changedValue: this.getChangedDisplayValue(this.result.dateOfBirthChanged),
    });
    array.push({
      label: `${this.documentTypeDescription.toUpperCase()} NUMBER`,
      extractedValue: this.getExtractedDisplayValue(this.result.idDocument.documentNumber, this.result.documentNumberExtracted, this.result.documentNumberChanged),
      changedValue: this.getChangedDisplayValue(this.result.documentNumberChanged),
    });
    if (this.secondaryDocumentNumber) {
      array.push({
        label: `${this.documentTypeDescription.toUpperCase()} VERSION NUMBER`,
        extractedValue: this.getExtractedDisplayValue(this.secondaryDocumentNumber, this.result.secondaryDocumentNumberExtracted, this.result.secondaryDocumentNumberChanged),
        changedValue: this.getChangedDisplayValue(this.result.secondaryDocumentNumberChanged),
      });
    }
    array.push({
      label: 'DATE OF EXPIRY',
      extractedValue: this.getExtractedDisplayValue(this.documentExpiryDateString, this.result.documentExpiryDateExtracted, this.result.documentExpiryDateChanged),
      changedValue: this.getChangedDisplayValue(this.result.documentExpiryDateChanged),
    });
    return array;
  }

  tdClass(value: string): string {
    if (value === 'No data extracted' || value === 'No change') {
      return 'no-data';
    }
    return '';
  }

  private getExtractedDisplayValue(registrationDetailsValue: string, extractedValue: string, changedValue: string): string {
    if (extractedValue) {
      return extractedValue;
    } else if (!changedValue) {
      return registrationDetailsValue;
    } else {
      return 'No data extracted';
    }
  }

  private getChangedDisplayValue(changedValue: string): string {
    if (changedValue) {
      return changedValue;
    } else {
      return 'No change';
    }
  }

  private dateToString(date: SimpleDate) {
    if (date && date.day && date.month && date.year) {
      return `${date.day}/${date.month}/${date.year}`;
    }
    return '';
  }

}

export interface ExtractedData {
  label: string;
  extractedValue: string;
  changedValue: string;
}
