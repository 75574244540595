import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CognitoUtil } from './cognito.service';
import { CustomerAccount } from '../shared/model/customer-account';

@Injectable()
export class CustomerAccountClientService {

  constructor(
    private http: HttpClient,
    public cognitoUtil: CognitoUtil
  ) { }

  register(customerAccount: CustomerAccount): Promise<any> {
    return this.cognitoUtil.getIdToken().then(token => {
      return token;
    }).then(token => {
      const url = environment.bioverifyApiURL + `/customer`;
      console.log('Calling ' + url);
      return this.http.post(url, customerAccount, {
        headers: {'Authorization': token }
      }).toPromise();
    });
  }

}
