import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CognitoUtil } from './cognito.service';
import { Address } from '../shared/model/address';

@Injectable()
export class VerificationClientService {

  constructor(
    private http: HttpClient,
    public cognitoUtil: CognitoUtil
  ) { }

  getVerificationWithUserAuthorization(verificationId: string, invitationId: string): Promise<any> {
    return this.cognitoUtil.getIdToken().then(token => {
      return token;
    }).then(token => {
      const url = environment.bioverifyApiURL + `/verification/${verificationId}?invitationId=${invitationId}`;
      console.log('Calling ' + url);
      return this.http.get(url, {
        headers: {'Authorization': token }
      }).toPromise();
    });
  }

  getVerificationWithKeyAuthorization(verificationId: string, invitationId: string, apiKey: string): Promise<any> {
    const url = environment.bioverifyApiURL + `/v1/verification/${verificationId}?invitationId=${invitationId}`;
    console.log('Calling ' + url);
    return this.http.get(url, {
      headers: {'x-api-key': apiKey }
    }).toPromise();
  }


  getImageCollectionWithKeyAuthorization(verificationId: string, invitationId: string, apiKey: string): Promise<any> {
    const url = environment.bioverifyApiURL + `/client/image-collection/${invitationId}?verificationId=${verificationId}`;
    console.log('Calling ' + url);
    return this.http.get(url, {
      headers: {'x-api-key': apiKey }
    }).toPromise();
  }

  saveAddress(invitationId: string, residentialAddress: Address): Promise<any> {
    const url = environment.bioverifyApiURL + `/invitation/address`;
    const body = {
      invitationId: invitationId,
      residentialAddress: residentialAddress,
    };
    console.log(`Posting to ${url} with body ${JSON.stringify(body, null, 4)}`);
    return this.http.post(url, body).toPromise();
  }

  getFullImagePath(relativePath: string): string {
    return environment.bioverifyApiURL + `/client/images/${relativePath}`;
  }
}
