import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCollectionRepositoryService } from '../../service/image-collection-repository.service';
import { MatDialog } from '@angular/material/dialog';
import { BankAccount } from '../../shared/model/bank-account';
import { BrandingService } from '../../service/branding.service';

@Component({
  selector: 'app-bank-account-collection',
  templateUrl: './bank-account-collection.component.html',
  styleUrls: ['./bank-account-collection.component.css']
})
export class BankAccountCollectionComponent extends BioVerifyComponent implements OnInit {

  get logoUrl(): string {
    return this.brandingService.logoUrl;
  }

  public accountForm: FormGroup;
  private invitationId: string;
  private isSaving = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private imageCollectionRepositoryService: ImageCollectionRepositoryService,
    private brandingService: BrandingService,
    private dialog: MatDialog,
  ) {
    super();
  }

  public ngOnInit() {
    this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    this.buildForm();
  }

  public submit() {
    if (this.isSaving) {
      return;
    }
    if (this.accountForm.valid) {
      this.showProgressSpinner(this.dialog);
      this.isSaving = true;
      const bankAccount = this.accountForm.value as BankAccount;
      this.imageCollectionRepositoryService.captureComplete(this.invitationId, this.imageCollectionRepositoryService.imageCollectionViewModel, bankAccount).then(id => {
        this.hideProgressSpinner();
        this.isSaving = false;
        this.router.navigate(['client/confirmation'], { replaceUrl: true });
      }).catch(err => {
        console.log(`Error ${err.message}`);
        this.hideProgressSpinner();
        this.isSaving = false;
        this.showAlert(this.dialog, 'PROBLEM', `Failed to save account details ${JSON.stringify(err)}`);
      });
    }
  }

  private buildForm(): void {
    this.accountForm = this.fb.group({
      'accountName': ['', [Validators.required]],
      'bankName': ['', [Validators.required]],
      'bsbCode': ['', [Validators.required]],
      'accountNumber': ['', [Validators.required]],
      'swiftCode': ['', []],
    });

  }

}
