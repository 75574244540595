import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {UserRegistrationService} from './service/user-registration.service';
import {UserParametersService} from './service/user-parameters.service';
import {UserLoginService} from './service/user-login.service';
import {CognitoUtil} from './service/cognito.service';
import {routing} from './app.routes';
import {PrivacyComponent, HomeComponent, HomeLandingComponent} from './public/home.component';
import {AwsUtil} from './service/aws.service';
import {UseractivityComponent} from './secure/useractivity/useractivity.component';
import {MyProfileComponent} from './secure/profile/myprofile.component';
import {SecureHomeComponent} from './secure/landing/securehome.component';
import {InvitationsComponent} from './secure/invitations/invitations.component';
import {LoginComponent} from './public/auth/login/login.component';
import {RegisterComponent} from './public/auth/register/registration.component';
import {ForgotPassword2Component, ForgotPasswordStep1Component} from './public/auth/forgot/forgotPassword.component';
import {LogoutComponent, RegistrationConfirmationComponent} from './public/auth/confirm/confirmRegistration.component';
import {ResendCodeComponent} from './public/auth/resend/resendCode.component';
import {NewPasswordComponent} from './public/auth/newpassword/newpassword.component';
import { MFAComponent } from './public/auth/mfa/mfa.component';
import { MaterialModule } from './material.module';
import { InvitationService } from './service/invitation.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressSpinnerComponent } from './shared/progress-spinner/progress-spinner.component';
import { AlertComponent } from './shared/alert/alert.component';
import { VerificationResultComponent } from './secure/verification-result/verification-result.component';
import { VerificationClientService } from './service/verification-client.service';
import { CustomerAccountComponent } from './secure/customer-account/customer-account.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { InvitationPipe } from './secure/invitations/invitation.pipe';
import { CounterPipe } from './shared/counter.pipe';
import { CustomerAccountClientService } from './service/customer-account-client.service';
import { UserDetailsService } from './service/user-details.service';
import { InviteLinksComponent } from './secure/invite-links/invite-links.component';
import { LinkDisplayComponent } from './secure/link-display/link-display.component';
import { ClipboardModule } from 'ngx-clipboard';
import { PublicInvitationsComponent } from './public/public-invitations/public-invitations.component';
import { NewInviteComponent } from './secure/new-invite/new-invite.component';
import { DynamicLinkComponent } from './public/dynamic-link/dynamic-link.component';
import { ImageCollectionResultComponent } from './secure/image-collection-result/image-collection-result.component';
import { ImageCollectionComponent } from './public/image-collection/image-collection.component';
import { PhotoCaptureComponent } from './public/photo-capture/photo-capture.component';
import { ImageCollectionRepositoryService } from './service/image-collection-repository.service';
import { CaptureConfirmationComponent } from './public/capture-confirmation/capture-confirmation.component';
import { VerificationsComponent } from './secure/verifications/verifications.component';
import { BankAccountCollectionComponent } from './public/bank-account-collection/bank-account-collection.component';
import { DocumentationComponent } from './public/documentation/documentation.component';
import { AddressCaptureComponent } from './public/address-capture/address-capture.component';
import { SimulationChoicesCaptureComponent } from './public/simulation-choices-capture/simulation-choices-capture.component';
import { SimulationService } from './service/simulation.service';
import { LivenessComponent } from './public/liveness/liveness.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CaptureCancelledComponent } from './public/capture-cancelled/capture-cancelled.component';

@NgModule({
  declarations: [
    NewPasswordComponent,
    LoginComponent,
    LogoutComponent,
    RegistrationConfirmationComponent,
    ResendCodeComponent,
    ForgotPasswordStep1Component,
    ForgotPassword2Component,
    RegisterComponent,
    MFAComponent,
    PrivacyComponent,
    DynamicLinkComponent,
    HomeLandingComponent,
    HomeComponent,
    UseractivityComponent,
    VerificationsComponent,
    MyProfileComponent,
    SecureHomeComponent,
    InvitationsComponent,
    AppComponent,
    ProgressSpinnerComponent,
    AlertComponent,
    LinkDisplayComponent,
    VerificationResultComponent,
    CustomerAccountComponent,
    InvitationPipe,
    CounterPipe,
    InviteLinksComponent,
    LinkDisplayComponent,
    PublicInvitationsComponent,
    NewInviteComponent,
    ImageCollectionResultComponent,
    ImageCollectionComponent,
    PhotoCaptureComponent,
    CaptureConfirmationComponent,
    BankAccountCollectionComponent,
    DocumentationComponent,
    AddressCaptureComponent,
    SimulationChoicesCaptureComponent,
    LivenessComponent,
    CaptureCancelledComponent,
],
imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    MaterialModule,
    NgxStripeModule.forRoot(environment.paymentKey),
    ClipboardModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule
],
entryComponents: [
  ProgressSpinnerComponent,
  AlertComponent,
  LinkDisplayComponent,
  NewInviteComponent
],
providers: [
    CognitoUtil,
    AwsUtil,
    UserRegistrationService,
    UserLoginService,
    UserParametersService,
    InvitationService,
    VerificationClientService,
    CustomerAccountClientService,
    UserDetailsService,
    ImageCollectionRepositoryService,
    SimulationService,
],
bootstrap: [AppComponent]
})
export class AppModule { }
