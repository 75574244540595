import { AbstractControl, FormsModule } from '@angular/forms';

/**
 * Provides a set of custom validators used by form controls.
 *
 * A validator is a function that processes a {\@link FormControl} or collection of
 * controls and returns a map of errors. A null map means that validation has passed.
 *
 * ### Example
 *
 * ```typescript
 * var loginControl = new FormControl("", Validators.required)
 * ```
 *
 * \@stable
 */
 export class CustomValidators {

  static mobileNumberInSupportedCountry(control: AbstractControl) {
    if (control.value == null || control.value.length === 0) {
      return null;
    }
    const NZ_MOBILE_REGEXP = /^\+64((20|21|22|27|28|29)\d{6,8})$/g;
    const AU_MOBILE_REGEXP = /^\+61((4|5)\d{8})$/g;
    const UK_MOBILE_REGEXP = /^\+44((73|74|75|76|77|78|79)\d{8})$/g;
    const numberWithoutWhitespace: string = control.value.replace(/\s/g, '');

    const isNZ = NZ_MOBILE_REGEXP.test(numberWithoutWhitespace);
    const isAU = AU_MOBILE_REGEXP.test(numberWithoutWhitespace);
    const isUK = UK_MOBILE_REGEXP.test(numberWithoutWhitespace);
    if (isNZ || isAU || isUK) {
      return null;
    }
    return { 'mobile': true };
  }

  static isSupportedEmailAddress(control: AbstractControl){
    if (control.value == null || control.value.length === 0) {
      return null;
    }
    const emailWithoutWhitespace: string = control.value.replace(/\s/g, '');
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = EMAIL_REGEXP.test(emailWithoutWhitespace);
    if (isValid) {
      return null;
    }
    return { 'email': true };
  }

}
