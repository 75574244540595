import { Component, OnInit } from '@angular/core';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { LoggedInCallback } from '../../service/cognito.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvitationService } from '../../service/invitation.service';
import { UserLoginService } from '../../service/user-login.service';
import { UserDetailsService } from '../../service/user-details.service';
import { environment } from '../../../environments/environment';
import { LinkDisplayComponent } from '../link-display/link-display.component';
import { ClipboardService } from 'ngx-clipboard';
import { UserDetails } from '../../shared/model/user-details';
import { PreInvitation } from '../../shared/model/pre-invitation';

@Component({
  selector: 'app-invite-links',
  templateUrl: './invite-links.component.html',
  styleUrls: ['./invite-links.component.css']
})
export class InviteLinksComponent extends BioVerifyComponent implements LoggedInCallback, OnInit {

  isSaving = false;
  form: FormGroup;
  userDetails: UserDetails;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public invitationService: InvitationService,
    public userService: UserLoginService,
    public userDetailsService: UserDetailsService,
  ) {
    super();
    this.userService.isAuthenticated(this);
    console.log('in InviteLinksComponent');
  }

  ngOnInit() {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      console.log('Checking user type');
      this.userDetailsService.getUserDetails().then(userDetails => {
        console.log(`getUserDetails returned: ${JSON.stringify(userDetails)}`);
        const usersHomePath = this.usersHomePath(userDetails);
        if (usersHomePath === this.router.url) {
          this.userDetails = userDetails;
          this.buildForm();
        } else {
          console.log(`Navigating to ${usersHomePath}`);
          this.router.navigate([usersHomePath]);
        }
      }).catch(err => {
        console.log(`getUserDetails failed: ${err.message}`);
        this.showAlert(this.dialog, 'PROBLEM', 'Oops, something is not right - please try again later');
        this.router.navigate(['/home/login']);
      });
    }
  }

  buildForm(): void {
    this.form = this.fb.group({
      'mobile': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });
  }

  generate() {
    if (this.isSaving) {
      return;
    }
    if (this.form.valid) {
      this.isSaving = true;
      this.showProgressSpinner(this.dialog);
      const lastFourDigits = this.form.get('mobile').value;
      console.log(`Generating invite link for ${lastFourDigits}`);
      this.invitationService.generateLink(lastFourDigits).then(preInvitation => {
        console.log(`Sent successfully`);
        this.form.reset();
        this.isSaving = false;
        this.hideProgressSpinner();
        this.showLink(preInvitation);
      }).catch(err => {
        console.log(`Send failed: ${err.message}`);
        this.isSaving = false;
        this.hideProgressSpinner();
        const message: string = err.error.errorMessage;
        if (message.startsWith('[400] ') || message.startsWith('[401] ')) {
          this.showAlert(this.dialog, 'PROBLEM', `The invite link could not be generated - ${message.substr(5)}`);
        } else {
          this.showAlert(this.dialog, 'PROBLEM', 'The invite link could not be generated - please try again later');
        }
      });
    }

  }

  showLink(preInvitation: PreInvitation) {
    const link = `${environment.bioverifyApiURL.replace('api.', '')}/invite/hsbc/${preInvitation.id}`;
    const dialogRef = this.dialog.open(LinkDisplayComponent, {
      width: '500px',
      data: { title: `LINK FOR ${preInvitation.forMobileNumberEnding}`,  link: link }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

}
