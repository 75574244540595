import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-link',
  templateUrl: './dynamic-link.component.html',
  styleUrls: ['./dynamic-link.component.css']
})
export class DynamicLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
