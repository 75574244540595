import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InvitationService } from '../../service/invitation.service';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageCollectionRepositoryService } from '../../service/image-collection-repository.service';
import { Config } from '../../shared/model/config';
import { DOCUMENT } from '@angular/common';
import { BrandingService } from '../../service/branding.service';

@Component({
  selector: 'app-image-collection',
  templateUrl: './image-collection.component.html',
  styleUrls: ['./image-collection.component.css']
})

export class ImageCollectionComponent extends BioVerifyComponent implements OnInit {

  public config: Config;
  public isMobile = true;
  public isCameraAvailable = true;

  get logoUrl(): string {
    return this.brandingService.logoUrl;
  }

  get showGregsonContent(): boolean {
    return this.config && this.config.sdkConfig && this.config.sdkConfig.verificationType === 'AU_COLLECT_DOCUMENTS';
  }

  get showUkContent(): boolean {
    return this.config && this.config.sdkConfig && this.config.sdkConfig.verificationType === 'UK_STANDARD_BIOMETRIC';
  }

  get imagesToCapture(): string[] {
    return this.imageCollectionRepositoryService.imageCollectionViewModel.filter(modelEntry => {
      return modelEntry.code !== 'photo-id';
    }).map(modelEntry => {
      return modelEntry.name;
    });
  }
  private invitationId: string;


  gregsonPrivacyPolicy = `Gregson & Associates collects your personal information to comply with the law. Its privacy policy is here:<br>
<a href='https://gregsonandassociates.com.au/privacy' target='_blank'>gregsonandassociates.com.au/privacy</a><br><br>`;

  bioVerifyPrivacyPolicy = `BioVerify provides a service that collects this information.<br>
BioVerify's privacy policy is here: <a href='https://bioverify.me/privacy-policy' target='_blank'>https://bioverify.me/privacy-policy</a>`;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageCollectionRepositoryService: ImageCollectionRepositoryService,
    private invitationService: InvitationService,
    private brandingService: BrandingService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
   }

  ngOnInit() {
    this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    this.isMobile = this.isRunningOnMobile(window.navigator.userAgent);
    if (this.isMobile) {
      if (navigator.mediaDevices) {
        this.redeemInvite();
      } else {
        this.isCameraAvailable = false;
      }
    }
  }

  redeemInvite() {
    this.showProgressSpinner(this.dialog);
    this.invitationService.redeemInvite(this.invitationId).then(config => {
      console.log(`Redeem return config ${JSON.stringify(config, null, 4)}`);
      this.hideProgressSpinner();
      this.config = config as Config;
      if (this.showGregsonContent) {
        this.imageCollectionRepositoryService.setCaptureFlowType(this.config.sdkConfig.captureFlowType);
        // TODO: Remove this once Gregson branding is returned from the server.
        this.brandingService.setBranding({
          logoUrl: '../assets/logos/gregson-logo.png',
        });
      } else {
        this.brandingService.setBranding(this.config.branding);
      }

    }).catch(err => {
      console.log(`Error ${JSON.stringify(err)}`);
      this.hideProgressSpinner();
      const message = err.error && err.error.errorMessage as string;
      if (message && message.startsWith('[403] ')) {
        this.showAlert(this.dialog, 'PROBLEM', message.substr(6));
      } else {
        this.showAlert(this.dialog, 'PROBLEM', `Failed to redeem invitation - please try again later`);
      }
    });
  }

  start() {
    console.log('Start');
    if (this.config && this.config.sdkConfig && this.config.sdkConfig.captureUrl) {
      const captureUrl = this.config.sdkConfig.captureUrl as string;
      if (this.showGregsonContent) {
        this.document.location.href = captureUrl;
      } else if (captureUrl.indexOf('simulation') >= 0) {
        // Skip over address capture when in simulation mode
        this.document.location.href = captureUrl;
      } else {
        this.router.navigate(['client/address', this.invitationId], { replaceUrl: true, queryParams: { captureUrl: this.config.sdkConfig.captureUrl, verificationType: this.config.sdkConfig.verificationType} });
      }
    } else {
      this.router.navigate(['client/capture', this.invitationId], { replaceUrl: true });
    }

  }

  showPrivacy() {
    if (this.showGregsonContent) {
      // TODO: Remove this once Gregson messages are returned from the server.
      this.showAlert(this.dialog, 'PRIVACY POLICY', `${this.gregsonPrivacyPolicy}${this.bioVerifyPrivacyPolicy}`);
    } else if (this.config && this.config.messages && this.config.messages.privacyPolicy) {
      this.showAlert(this.dialog, 'PRIVACY POLICY', `${this.config.messages.privacyPolicy}${this.bioVerifyPrivacyPolicy}`);
    } else {
      this.showAlert(this.dialog, 'PRIVACY POLICY', `${this.bioVerifyPrivacyPolicy.replace('this information', 'personal information')}`);
    }
  }

  isRunningOnMobile(ua: string): boolean {
    // Regex from http://detectmobilebrowsers.com
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0, 4))) {
      console.log(`Mobile browser detected ${ua}`);
      return true;
    } else {
      console.log(`No mobile browser detected ${ua}`);
      return false;
    }
  }
}
