import { Invitation, VerificationType } from './invitation';

export class ExtendedInvitation implements Invitation {

  private invitation: Invitation;

  constructor(invitation: Invitation) {
    this.invitation = invitation;
  }

  get verificationId(): string {
    return this.invitation.verificationId || '';
  }

  get sentDatetime(): number {
    return this.invitation.sentDatetime;
  }

  get firstName(): string {
    return this.invitation.firstName || '';
  }

  get lastName(): string {
    return this.invitation.lastName || '';
  }

  get clientName(): string {
    return this.invitation.clientName || '';
  }

  get redeemedDatetime(): number {
    return this.invitation.redeemedDatetime;
  }

  get completedDatetime(): number {
    return this.invitation.completedDatetime;
  }

  get verificationType(): VerificationType {
    return this.invitation.verificationType || 'NZ_STANDARD_BIOMETRIC';
  }

  get isBiometric(): boolean {
    return this.verificationType.indexOf('BIOMETRIC') >= 0;
  }

  get clientNameWithReference(): string {
    if (this.invitation.clientReference) {
      return `${this.clientName} - ${this.invitation.clientReference}`;
    } else {
      return `${this.clientName}`;
    }
  }

  get fullName(): string {
    return `${this.lastName}, ${this.firstName}`;
  }

  get fullNameWithReference(): string {
    if (this.invitation.individualId) {
      return `${this.fullName} - ${this.invitation.individualId}`;
    } else {
      return `${this.fullName}`;
    }
  }

  get sentTo(): string {
    if (this.invitation.mobile) {
      return this.invitation.mobile;
    } else {
      return this.invitation.email || '';
    }
  }

}
