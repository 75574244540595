import { Injectable } from '@angular/core';
import { Branding } from '../shared/model/config';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  private branding: Branding;

  get logoUrl(): string {
    if (this.branding && this.branding.logoUrl) {
      return this.branding.logoUrl;
    }
    return '../assets/img/BioVerifyLogo.png';
  }

  constructor() { }

  public setBranding(branding: Branding) {
    this.branding = branding;
  }
}
