import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ImageCollectionViewModel } from '../shared/model/image-collection-view-model';
import { BankAccount } from '../shared/model/bank-account';

@Injectable()
export class ImageCollectionRepositoryService {

  public get imageCollectionViewModel(): ImageCollectionViewModel[] {
    if (this.captureFlowType === 'beneficiary' || this.captureFlowType === 'planning') {
      return this._imageCollectionViewModel.filter(viewModel => {
        return viewModel.code !== 'will' && viewModel.code !== 'death-cert' && viewModel.code !== 'concession';
      });
    } else if (this.captureFlowType === 'executor') {
      return this._imageCollectionViewModel.filter(viewModel => {
        return viewModel.code !== 'concession';
      });
    }
    return this._imageCollectionViewModel;
  }

  // TODO Move this model to the server
  private _imageCollectionViewModel: ImageCollectionViewModel[] = [
    new ImageCollectionViewModel({
      code: 'photo-id',
      name: 'Other Photo ID',
      description: 'Please capture another Photo ID',
      mandatory: false,
      multiPage: false,
      cameraFacingMode: 'environment',
    }),
    new ImageCollectionViewModel({
      code: 'medicare',
      name: 'Australian Medicare Card',
      description: 'Please capture your Australian Medicare Card',
      mandatory: false,
      multiPage: false,
      cameraFacingMode: 'environment',
    }),
    new ImageCollectionViewModel({
      code: 'concession',
      name: 'Concession Card',
      description: 'Please capture your Concession Card',
      mandatory: false,
      multiPage: false,
      cameraFacingMode: 'environment',
    }),
    new ImageCollectionViewModel({
      code: 'death-cert',
      name: `Deceased's Death Certificate`,
      description: 'Please capture the Death Certificate',
      mandatory: false,
      multiPage: false,
      cameraFacingMode: 'environment',
    }),
    new ImageCollectionViewModel({
      code: 'will',
      name: `Deceased's Will`,
      description: `Please capture the first page of the Will. Press “I don't have one” if there is no Will`,
      mandatory: false,
      multiPage: true,
      cameraFacingMode: 'environment',
    }),
  ];

  private captureFlowType = 'executor';

  constructor(
    private http: HttpClient,
  ) { }

  public setCaptureFlowType(captureFlowType: string) {
    this.captureFlowType = captureFlowType;
  }

  public send(inviteToken: string, photoData: string, filename: string): Promise<object> {
    const url = `${environment.bioverifyApiURL}/client/images`;
    console.log('Calling ' + url);
    const body = {
      inviteToken: inviteToken,
      photoData: photoData,
      filename: filename,
    };
    return this.http.post(url, body).toPromise();
  }

  public captureComplete(inviteToken: string, imageCollectionViewModel: ImageCollectionViewModel[], bankAccount: BankAccount): Promise<object> {
    const url = `${environment.bioverifyApiURL}/client/image-collection/${inviteToken}`;
    console.log('Calling ' + url);
    return this.http.post(url, {
      model: imageCollectionViewModel,
      bankAccount: bankAccount,
    }).toPromise();
  }

}
