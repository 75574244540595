import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {PrivacyComponent, HomeComponent, HomeLandingComponent} from './public/home.component';
import {SecureHomeComponent} from './secure/landing/securehome.component';
import {MyProfileComponent} from './secure/profile/myprofile.component';
import {InvitationsComponent} from './secure/invitations/invitations.component';
import {UseractivityComponent} from './secure/useractivity/useractivity.component';
import {LoginComponent} from './public/auth/login/login.component';
import {RegisterComponent} from './public/auth/register/registration.component';
import {ForgotPassword2Component, ForgotPasswordStep1Component} from './public/auth/forgot/forgotPassword.component';
import {LogoutComponent, RegistrationConfirmationComponent} from './public/auth/confirm/confirmRegistration.component';
import {ResendCodeComponent} from './public/auth/resend/resendCode.component';
import {NewPasswordComponent} from './public/auth/newpassword/newpassword.component';
import { VerificationResultComponent } from './secure/verification-result/verification-result.component';
import { CustomerAccountComponent } from './secure/customer-account/customer-account.component';
import { InviteLinksComponent } from './secure/invite-links/invite-links.component';
import { PublicInvitationsComponent } from './public/public-invitations/public-invitations.component';
import { DynamicLinkComponent } from './public/dynamic-link/dynamic-link.component';
import { ImageCollectionResultComponent } from './secure/image-collection-result/image-collection-result.component';
import { PhotoCaptureComponent } from './public/photo-capture/photo-capture.component';
import { ImageCollectionComponent } from './public/image-collection/image-collection.component';
import { CaptureConfirmationComponent } from './public/capture-confirmation/capture-confirmation.component';
import { VerificationsComponent } from './secure/verifications/verifications.component';
import { BankAccountCollectionComponent } from './public/bank-account-collection/bank-account-collection.component';
import { DocumentationComponent } from './public/documentation/documentation.component';
import { SimulationChoicesCaptureComponent } from './public/simulation-choices-capture/simulation-choices-capture.component';
import { LivenessComponent } from './public/liveness/liveness.component';
import { AddressCaptureComponent } from './public/address-capture/address-capture.component';
import { CaptureCancelledComponent } from './public/capture-cancelled/capture-cancelled.component';

const homeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        children: [
            {path: 'privacy', component: PrivacyComponent},
            {path: 'api', component: DocumentationComponent},
            {path: 'dynamiclink', component: DynamicLinkComponent},
            {path: 'login', component: LoginComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent},
            {path: 'resendCode', component: ResendCodeComponent},
            {path: 'forgotPassword/:email', component: ForgotPassword2Component},
            {path: 'forgotPassword', component: ForgotPasswordStep1Component},
            {path: 'newPassword', component: NewPasswordComponent},
            {path: '', component: HomeLandingComponent},
        ]
    },
];

const secureHomeRoutes: Routes = [
    {

        path: '',
        redirectTo: '/securehome',
        pathMatch: 'full'
    },
    {
        path: 'securehome', component: SecureHomeComponent, children: [
        {path: 'logout', component: LogoutComponent},
        {path: 'invitations', component: InvitationsComponent},
        {path: 'invite-links', component: InviteLinksComponent},
        {path: 'myprofile', component: MyProfileComponent},
        {path: 'useractivity', component: UseractivityComponent},
        {path: 'verifications', component: VerificationsComponent},
        {path: 'register', component: CustomerAccountComponent},
        {path: '', component: UseractivityComponent}]
    }
];

const routes: Routes = [
    {
        path: '',
        children: [
            ...homeRoutes,
            ...secureHomeRoutes,
            {
                path: '',
                component: HomeComponent
            },
            { path: 'invite/:customer/:preInvitationId', component: PublicInvitationsComponent},
            { path: 'verification/:invitationId/:verificationId', component: VerificationResultComponent},
            { path: 'image-collection/:invitationId/:verificationId', component: ImageCollectionResultComponent},
            { path: 'client/start/:invitationId',  component: ImageCollectionComponent },
            { path: 'client/address/:invitationId',  component: AddressCaptureComponent },
            { path: 'client/capture/:invitationId',  component: PhotoCaptureComponent },
            { path: 'client/bank-account/:invitationId',  component: BankAccountCollectionComponent },
            { path: 'client/confirmation',  component: CaptureConfirmationComponent },
            { path: 'client/cancelled',  component: CaptureCancelledComponent },
            { path: 'client/liveness/:invitationId/:verificationId', component: LivenessComponent},
            { path: 'client/simulation-choices-capture/:invitationId',  component: SimulationChoicesCaptureComponent },
          ],

    },


];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
