export class ImageCollectionViewModel {

  code: string;
  name: string;
  description: string;
  mandatory: boolean;
  multiPage: boolean;
  cameraFacingMode: string;

  public pagesCaptured = 0;
  public captureComplete = false;

  constructor(values: {
    code: string,
    name: string,
    description: string,
    mandatory: boolean,
    multiPage: boolean;
    cameraFacingMode: 'environment' | 'user'
  }) {
    this.code = values.code;
    this.name = values.name;
    this.description = values.description;
    this.mandatory = values.mandatory;
    this.multiPage = values.multiPage;
    this.cameraFacingMode = values.cameraFacingMode;
  }

  addPage() {
    this.pagesCaptured = this.pagesCaptured + 1;
  }
}
