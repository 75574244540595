import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CognitoUtil } from './cognito.service';
import { SimulationChoices } from '../shared/model/simulation-choices';

@Injectable()
export class SimulationService {

  constructor(
    private http: HttpClient,
    public cognitoUtil: CognitoUtil
  ) { }

  submitChoices(invitationId: string, simulationChoices: SimulationChoices): Promise<any> {
    const url = environment.bioverifyApiURL + `/client/simulation/${invitationId}`;
    console.log('Calling ' + url);
    return this.http.post(url, simulationChoices).toPromise();
  }

}
