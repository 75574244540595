import {Component, OnInit, ViewChild} from '@angular/core';
import {UserLoginService} from '../../service/user-login.service';
import {LoggedInCallback} from '../../service/cognito.service';
import {Router} from '@angular/router';
import { InvitationService } from '../../service/invitation.service';
import { Invitation } from '../../shared/model/invitation';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { UserDetailsService } from '../../service/user-details.service';
import { NewInviteComponent } from '../new-invite/new-invite.component';
import { environment } from '../../../environments/environment';
import { UserDetails } from '../../shared/model/user-details';
import { ExtendedInvitation } from '../../shared/model/extended-invitation';

const ELEMENT_DATA: ExtendedInvitation[] = [];

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './useractivity.html',
    styleUrls: ['./useractivity.css']
})
export class UseractivityComponent extends BioVerifyComponent implements LoggedInCallback, OnInit  {

  userDetails: UserDetails;

  get displayedColumns(): string[] {
    if (this.userDetails && this.userDetails.userType && this.userDetails.userType === 'full') {
      return ['fullNameWithReference', 'sentTo', 'sentDatetime', 'redeemedDatetime', 'verificationId'];
    } else if (this.userDetails && this.userDetails.primaryVerificationType === 'AU_COLLECT_DOCUMENTS') {
      return ['fullNameWithReference', 'sentTo', 'sentDatetime', 'completedDatetime'];
    } else {
      return ['fullNameWithReference', 'sentTo', 'sentDatetime', 'redeemedDatetime', 'verificationExpress'];
    }
  }
  footerColumns = ['fullNameWithReference'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);


  get footerMessage(): string {
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length > 0) {
      return `Total invitations sent: ${this.dataSource.data.length}`;
    }
    return 'No invitations have been sent. Tap the + button to send one.';
  }
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    public invitationService: InvitationService,
    public userService: UserLoginService,
    public userDetailsService: UserDetailsService
  ) {
    super();
    this.userService.isAuthenticated(this);
    console.log('in UseractivityComponent');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      console.log('Checking user type');
      this.userDetailsService.getUserDetails().then(userDetails => {
        console.log(`getUserDetails returned: ${JSON.stringify(userDetails)}`);
        const usersHomePath = this.usersHomePath(userDetails);
        if (usersHomePath === this.router.url) {
          this.userDetails = userDetails;
          this.loadInvitations();
        } else {
          console.log(`Navigating to ${usersHomePath}`);
          this.router.navigate([usersHomePath]);
        }
      }).catch(err => {
        console.log(`getUserDetails failed: ${err.message}`);
        this.showAlert(this.dialog, 'PROBLEM', 'Oops, something is not right - please try again later');
        this.router.navigate(['/home/login']);
      });
    }
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadInvitations() {
    this.showProgressSpinner(this.dialog);
    this.invitationService.get().then(invitations => {
      const items = invitations.sort((invitation1, invitation2) => {
        return invitation2.sentDatetime - invitation1.sentDatetime;
      });
      this.dataSource.data = items.map(invitation => new ExtendedInvitation(invitation));
      this.hideProgressSpinner();
    }).catch(err => {
      this.hideProgressSpinner();
      console.log(`Get invitations failed: ${err.message}`);
      this.showAlert(this.dialog, 'PROBLEM', 'Invitations could not be retrieved - please try later.');
    });

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NewInviteComponent, {
      width: '400px',
      data: { userDetails: this.userDetails }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.loadInvitations();
      }
    });
  }

  cancel() {

  }

  reportUrl(invitation: Invitation): string {
    if (this.userDetails && this.userDetails.userType && this.userDetails.userType === 'full') {
      return `${environment.adminPanelURL}/person-details.seam?currentUserId=${invitation.verificationId || ''}`;
    } else {
      return '';
    }
  }
}
