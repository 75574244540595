import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { UserDetails } from './model/user-details';

export class BioVerifyComponent implements OnDestroy {

    ngUnsubscribe: Subject<void> = new Subject<void>();
    spinnerDialog: MatDialogRef<ProgressSpinnerComponent>;

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    usersHomePath(userDetails: UserDetails): string {
      if (userDetails.userType === 'unknown') {
        return '/securehome/register';
      } else if (userDetails.userType === 'full-combined') {
        return '/securehome/verifications';
      } else if (userDetails.userType === 'full' && userDetails.preInvites) {
        return '/securehome/invite-links';
      } else {
        return '/securehome';
      }
    }

    showErrors(form: FormGroup, formErrors: Object, validationMessages: Object, ignoreDirty: Boolean) {
      if (!form) { return; }

      for (const field of Object.keys(formErrors)) {
        // clear previous error message (if any)
        formErrors[field] = '';
        const control = form.get(field);

        if (control && (control.dirty || ignoreDirty) && !control.valid) {
          const messages = validationMessages[field];
          for (const key of Object.keys(control.errors)) {
            formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }

    showAlert(dialog: MatDialog, title: string, message: string, width?: string) {
      const dialogRef = dialog.open(AlertComponent, {
        width: width || '320px',
        data: { title: title,  message: message }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    }

    showOptionsDialog(dialog: MatDialog, title: string, message: string, button1: string, button2: string, onClose?: (buttonPressed: string) => void, width?: string) {
      const dialogRef = dialog.open(AlertComponent, {
        width: width || '320px',
        data: {
          title: title,
          message: message,
          button1: button1,
          button2: button2,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (onClose) {
          onClose(result);
        }
      });
    }


    showProgressSpinner(dialog: MatDialog) {
      this.spinnerDialog = dialog.open(ProgressSpinnerComponent, {
        width: '120px',
      });
    }

    hideProgressSpinner() {
      if (this.spinnerDialog) {
        this.spinnerDialog.close();
      }
    }

}
