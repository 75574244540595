import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'counter', pure: true})
export class CounterPipe implements PipeTransform {
  transform(value: any[]): string {
    if (value) {
      return value.length.toString();
    }
    return '0';
  }
}
