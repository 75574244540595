import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/CustomValidators';
import { InvitationService } from '../../service/invitation.service';
import { Invitation } from '../../shared/model/invitation';

@Component({
  selector: 'app-public-invitations',
  templateUrl: './public-invitations.component.html',
  styleUrls: ['./public-invitations.component.css']
})
export class PublicInvitationsComponent extends BioVerifyComponent implements OnInit {

  isSaving = false;
  sent = false;
  preInvitationId: string;
  shareForm: FormGroup;
  formErrors = {
    'firstName': '',
    'lastName': '',
    'mobile': '',
  };

  validationMessages = {
    'firstName': {
      'required':      'Please provide this information'
    },
    'lastName': {
      'required':      'Please provide this information'
    },
    'mobile': {
      'required':   'Please provide the mobile number of the invitee',
      'mobile':   'Please enter a valid NZ  mobile number (starting +642), AU  mobile number (starting +614 or +615) or UK mobile number (starting +447) '
    },
  };

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    public invitationService: InvitationService,
    ) {
    super();
    console.log('in VerificationResultComponent');
  }

  ngOnInit() {
    this.preInvitationId = this.route.snapshot.paramMap.get('preInvitationId');
    const customer = this.route.snapshot.paramMap.get('customer');
    console.log(`Opening link for ${customer} with id ${this.preInvitationId}`);
    this.buildForm();
  }

  buildForm(): void {
    this.shareForm = this.fb.group({
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'mobile': ['', [Validators.required, CustomValidators.mobileNumberInSupportedCountry]],
    });
  }

  showValidationErrors(ignoreDirty: Boolean) {
    this.showErrors(this.shareForm, this.formErrors, this.validationMessages, ignoreDirty);
  }

  send() {
    if (this.isSaving) {
      return;
    }
    this.showValidationErrors(true);
    if (this.shareForm.valid) {
      this.isSaving = true;
      this.showProgressSpinner(this.dialog);
      const invite: Invitation = this.shareForm.value;
      console.log(`Sending invitation ${JSON.stringify(invite)}`);
      this.invitationService.redeemPreInvite(this.preInvitationId, invite).then(result => {
        console.log(`Sent successfully`);
        this.shareForm.reset();
        // this.checked = false;
        this.isSaving = false;
        this.hideProgressSpinner();
        this.sent = true;
      }).catch(err => {
        console.log(`Send failed: ${err.message}`);
        this.isSaving = false;
        this.hideProgressSpinner();
        const message: string = err.error.errorMessage;
        if (message.startsWith('[400] ') || message.startsWith('[401] ')) {
          this.showAlert(this.dialog, 'PROBLEM', `The invitation could not be sent - ${message.substr(5)}`);
        } else {
          this.showAlert(this.dialog, 'PROBLEM', 'The invitation could not be sent - please try again later');
        }
      });
    }
  }
}
