import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  get button1(): string {
    return this.data.button1 || 'OK';
  }

  get button2(): string {
    return this.data.button2;
  }

  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  button1Click() {
    this.dialogRef.close(this.button1);
  }

  button2Click() {
    this.dialogRef.close(this.button2);
  }
}
