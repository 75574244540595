import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { ImageCollectionRepositoryService } from '../../service/image-collection-repository.service';
import { ImageCollectionViewModel } from '../../shared/model/image-collection-view-model';
import { BrandingService } from '../../service/branding.service';
import { VerificationType } from '../../shared/model/invitation';

@Component({
  selector: 'app-photo-capture',
  templateUrl: './photo-capture.component.html',
  styleUrls: ['./photo-capture.component.css'],
})
export class PhotoCaptureComponent extends BioVerifyComponent implements OnInit, OnDestroy {

  get videoElement(): HTMLVideoElement {
    return this.video.nativeElement as HTMLVideoElement;
  }

  get canvasElement(): HTMLCanvasElement {
    return this.captureCanvas.nativeElement as HTMLCanvasElement;
  }
  public capturing = true;

  @ViewChild('video', {static: false}) public video: ElementRef;
  @ViewChild('captureCanvas', {static: false}) public captureCanvas: ElementRef;

  private invitationId: string;
  private videoWith = 1080; // 1080; 640 and 800 work
  private track: MediaStreamTrack;
  private isSaving = false;

  private imageCollectionViewModel: ImageCollectionViewModel[];
  private nextDocumentToCapture: ImageCollectionViewModel;
  private verificationType: VerificationType;
  private captureUrl = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageCollectionRepositoryService: ImageCollectionRepositoryService,
    private brandingService: BrandingService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  public ngOnInit() {
    this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    this.captureUrl = this.route.snapshot.queryParamMap.get('captureUrl');
    this.verificationType = this.route.snapshot.queryParamMap.get('verificationType') as VerificationType;
    if (this.verificationType && this.verificationType === 'UK_STANDARD_BIOMETRIC') {
      this.imageCollectionViewModel = [new ImageCollectionViewModel({
        code: 'proof-of-address',
        name: 'Proof of address',
        description: 'Please capture proof of address (Utility bill or bank statement)',
        mandatory: true,
        multiPage: false,
        cameraFacingMode: 'environment',
      })];
    } else {
      const captureFlowType = this.route.snapshot.queryParamMap.get('captureFlowType');
      // TODO: If and when this page needs to support other clients, will need to get branding from the server.
      this.brandingService.setBranding({
        logoUrl: '../assets/logos/gregson-logo.png',
      });
      this.imageCollectionRepositoryService.setCaptureFlowType(captureFlowType);
      this.imageCollectionViewModel = this.imageCollectionRepositoryService.imageCollectionViewModel;
    }
    this.nextDocumentToCapture = this.imageCollectionViewModel[0];
    this.moveToNext();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.track) {
      this.track.stop();
    }
  }

  public moveToNext() {
    // Ask if more pages
    if (this.nextDocumentToCapture && this.nextDocumentToCapture.pagesCaptured === 1 && this.nextDocumentToCapture.multiPage === false) {
      this.nextDocumentToCapture.captureComplete = true;
      this.nextDocumentToCapture = this.imageCollectionViewModel.find(modelItem => {
        return modelItem.captureComplete === false;
      });
    }
    if (this.nextDocumentToCapture) {
      const message = this.nextDocumentToCapture.pagesCaptured === 0 ? this.nextDocumentToCapture.description :
      `Please capture page ${this.nextDocumentToCapture.pagesCaptured + 1}`;
      let button2;
      if (this.nextDocumentToCapture.pagesCaptured === 0 && this.nextDocumentToCapture.mandatory === false) {
        button2 = `I don't have one`;
      } else if (this.nextDocumentToCapture.pagesCaptured > 0) {
        button2 = 'No more pages';
      }
      this.showOptionsDialog(this.dialog, this.nextDocumentToCapture.name, message, 'Capture', button2, result => {
        if (result === `I don't have one` || result === 'No more pages' ) {
          this.nextDocumentToCapture.captureComplete = true;
          this.nextDocumentToCapture = this.imageCollectionViewModel.find(modelItem => {
            return modelItem.captureComplete === false;
          });
          this.moveToNext();
          return;
        }
        this.capturing = true;
        this.start({
          video: {
            facingMode: this.nextDocumentToCapture.cameraFacingMode,
            width: this.videoWith,
          },
        });
      });
    } else {
      if (this.verificationType && this.verificationType === 'UK_STANDARD_BIOMETRIC') {
        this.document.location.href = this.captureUrl;
      } else {
        this.router.navigate(['client/bank-account', this.invitationId], { replaceUrl: true });
      }
    }
  }

  public captureImage() {
    const canvas = this.canvasElement;
    canvas.width = this.videoElement.videoWidth;
    canvas.height = this.videoElement.videoHeight;
    canvas.getContext('2d').drawImage(this.videoElement, 0, 0);
    this.track.stop();
    this.capturing = false;
  }

  public retake() {
    this.capturing = true;
    this.start({
      video: {
        facingMode: this.nextDocumentToCapture.cameraFacingMode,
        width: this.videoWith,
      },
    });

  }
  public saveImage() {
    if (this.isSaving) {
      return;
    }
    this.showProgressSpinner(this.dialog);
    this.isSaving = true;
    // TODO: Send to server as a BLOB
    const data = this.canvasElement.toDataURL('image/jpeg', 0.8);
    this.imageCollectionRepositoryService.send(this.invitationId, data, `${this.nextDocumentToCapture.code}-page${this.nextDocumentToCapture.pagesCaptured + 1}.jpeg`).then(id => {
      this.hideProgressSpinner();
      this.isSaving = false;
      this.nextDocumentToCapture.addPage();
      this.moveToNext();
    }).catch(err => {
      console.log(`Error ${err.message}`);
      this.hideProgressSpinner();
      this.isSaving = false;
      this.showAlert(this.dialog, 'PROBLEM', `Failed to save image ${JSON.stringify(err)}`);
    });
  }

  private async start(constraints: any) {
    // await this.loadModels();
    const supportConstraints = navigator.mediaDevices.getSupportedConstraints();
    console.log(`supportConstraints ${JSON.stringify(supportConstraints, null, 4)}`);

    navigator.mediaDevices.getUserMedia(constraints).then(mediaStream => {
      this.videoElement.srcObject = mediaStream;
      this.track = mediaStream.getVideoTracks()[0];
    }).catch(err => {
      this.showAlert(this.dialog, 'PROBLEM', `Error ${err.message}`);
    });
  }

}
