import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/CustomValidators';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { InvitationService } from '../../service/invitation.service';
import { Invitation } from '../../shared/model/invitation';
import { takeUntil } from 'rxjs/operators';
import { UserDetails } from '../../shared/model/user-details';
import { environment } from '../../../environments/environment';
import { LinkDisplayComponent } from '../link-display/link-display.component';

type Destination = 'mobile' | 'email' | 'link';

@Component({
  selector: 'app-new-invite',
  templateUrl: './new-invite.component.html',
  styleUrls: ['./new-invite.component.css']
})
export class NewInviteComponent extends BioVerifyComponent implements OnInit {

  formErrors = {
    'firstName': '',
    'lastName': '',
    'mobile': '',
    'email': '',
    'captureFlowType': '',
  };

  validationMessages = {
    'firstName': {
      'required':      'Please provide this information'
    },
    'lastName': {
      'required':      'Please provide this information'
    },
    'mobile': {
      'required':   'Please provide the mobile number of the invitee',
      'mobile':   'Please enter a valid NZ  mobile number (starting +642), AU  mobile number (starting +614 or +615) or UK mobile number (starting +447) '
    },
    'email': {
      'required':   'Please provide the email address of the invitee',
      'email':   'Please enter a valid email address'
    },
    'captureFlowType': {
      'required': 'Please select a type'
    },
  };

  isSaving = false;
  shareForm: FormGroup;
  userDetails: UserDetails;
  checked = false;
  destination: Destination = 'link';

  captureFlowTypes: any[] = [];
  get disabled(): boolean {
    return (this.userDetails.userType === 'express' && this.checked === false);
  }

  get costExcludingGst(): string {
    const transactionCost = this.userDetails.transactionCost;
    if (transactionCost) {
      if (this.isSubjectToGST) {
        const netCost = transactionCost * 100 / 115;
        return Number(netCost / 100).toString();
      } else {
        return Number(transactionCost / 100).toString();
      }
    }
    return '35';
  }

  get currency(): string {
    return this.userDetails.transactionCurrency || 'NZD';
  }

  get isSubjectToGST(): boolean {
    return this.userDetails.isSubjectToGST;
  }

  get submitButtonLabel(): string {
    return this.destination === 'link' ? 'GENERATE LINK' : 'SEND';
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<NewInviteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public invitationService: InvitationService,
  ) {
    super();
    this.userDetails = data.userDetails;
  }

  ngOnInit() {
    if (this.userDetails.primaryVerificationType === 'AU_COLLECT_DOCUMENTS') {
      this.captureFlowTypes = [
        {code: 'executor', description: 'Executor or administrator'},
        {code: 'beneficiary', description: 'Beneficiary'},
        {code: 'planning', description: 'Wills and estate planning'},
        {code: 'litigation', description: 'Litigation'}
      ];
    }
    this.buildForm();
  }

  buildForm(): void {
    this.shareForm = this.fb.group({
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'sendVia': ['link', [Validators.required]],
      'mobile': ['', []],
      'email': ['', []],
      'captureFlowType': ['', this.captureFlowTypes.length > 0 ? [Validators.required] : []],
    });

    this.shareForm.get('sendVia')
    .valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => this.updateValidators(data as Destination));

  }

  updateValidators(sendVia: Destination): void {
    console.log(`Send via ${sendVia}`);
    this.destination = sendVia;
    this.shareForm.get('mobile').setValidators(sendVia === 'mobile' ? [Validators.required, CustomValidators.mobileNumberInSupportedCountry] : []);
    this.shareForm.get('email').setValidators(sendVia === 'email' ? [Validators.required, CustomValidators.isSupportedEmailAddress] : []);
    this.shareForm.get('mobile').updateValueAndValidity();
    this.shareForm.get('email').updateValueAndValidity();
  }

  showValidationErrors(ignoreDirty: Boolean) {
    this.showErrors(this.shareForm, this.formErrors, this.validationMessages, ignoreDirty);
  }

  submit() {
    if (this.isSaving) {
      return;
    }
    this.showValidationErrors(true);
    if (this.shareForm.valid) {
      this.isSaving = true;
      const invite = this.getInvitation();
      this.send(invite, this.destination);
    }
  }

  private send(invite: Invitation, destination: Destination) {
    console.log(`Sending invitation ${JSON.stringify(invite)}`);
    this.invitationService.send(invite).then(result => {
      console.log(`Sent successfully`);
      this.shareForm.reset();
      this.checked = false;
      this.isSaving = false;
      this.dialogRef.close(true);
      if (destination === 'link') {
        this.showLink(result['invitationId'], `${invite.firstName} ${invite.lastName}`);
      }
    }).catch(err => {
      console.log(`Send failed: ${err.message}`);
      this.isSaving = false;
      this.hideProgressSpinner();
      const message: string = err.error.errorMessage;
      const problem = destination === 'link' ? 'The invite link could not be generated' : 'The invitation could not be sent';
      if (message.startsWith('[400] ') || message.startsWith('[401] ')) {
        this.showAlert(this.dialog, 'PROBLEM', `${problem} - ${message.substr(5)}`);
      } else {
        this.showAlert(this.dialog, 'PROBLEM', `${problem} - please try again later`);
      }
    });
  }

  private getInvitation(): Invitation {
    const formValues = this.shareForm.value;
    return {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      mobile: this.destination === 'mobile' ? formValues.mobile : undefined,
      email: this.destination === 'email' ? formValues.email : undefined,
      captureFlowType: formValues.captureFlowType,
    };
  }

  showLink(invitationId: string, name: string) {
    const link = `${environment.bioverifyApiURL.replace('api.', '')}/client/start/${invitationId}`;
    const dialogRef = this.dialog.open(LinkDisplayComponent, {
      width: '500px',
      disableClose: true,
      data: { title: `LINK FOR ${name}`,  link: link }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

}
