import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CognitoUtil } from './cognito.service';
import { UserDetails } from '../shared/model/user-details';
import { map } from 'rxjs/operators';
@Injectable()
export class UserDetailsService {

  constructor(
    private http: HttpClient,
    public cognitoUtil: CognitoUtil
  ) { }

  getUserDetails(): Promise<UserDetails> {
    return this.cognitoUtil.getIdToken().then(token => {
      return token;
    }).then(token => {
      const url = environment.bioverifyApiURL + `/user`;
      console.log('Calling ' + url);
      return this.http.get(url, {
        headers: {'Authorization': token }
      }).pipe(map(response => {
        return response as UserDetails;
      })).toPromise();
    });
  }

}
