import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-link-display',
  templateUrl: './link-display.component.html',
  styles: []
})
export class LinkDisplayComponent implements OnInit {

  constructor(
    private clipboardService: ClipboardService,
    public dialogRef: MatDialogRef<LinkDisplayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  copyLink(link: string) {
    this.clipboardService.copyFromContent(link);
    this.dialogRef.close(true);
  }
}
