import {Component, OnInit} from '@angular/core';
import {UserLoginService} from '../../service/user-login.service';
import {Callback, CognitoUtil, LoggedInCallback} from '../../service/cognito.service';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';
import { InvitationService } from '../../service/invitation.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { Invitation } from '../../shared/model/invitation';

export class Stuff {
    public accessToken: string;
    public idToken: string;
}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './invitations.html'
})
export class InvitationsComponent implements OnInit {

  items: Invitation[];
  get displayedColumns(): string[] {
    if (this.selectedAccount === 'all') {
      return ['account', 'name', 'mobile', 'sent', 'redeemed', 'identityCaptureStatus', 'verification'];
    } else if (this.selectedAccount === 'bioverify') {
      return ['sentBy', 'name', 'mobile', 'sent', 'redeemed', 'identityCaptureStatus', 'verification'];
    } else {
      return ['name', 'mobile', 'sent', 'redeemed', 'identityCaptureStatus', 'verification'];
    }
  }
  accounts = ['all', 'bioverify'];
  selectedAccount = 'all';

    public stuff: Stuff = new Stuff();

    constructor(
      public router: Router,
      public userService: UserLoginService,
      public cognitoUtil: CognitoUtil,
      public invitationService: InvitationService,
      private dialog: MatDialog
      ) {
        console.log('in InvitationsComponent');

    }

    ngOnInit() {
      this.invitationService.get().then(invitations => {
        this.items = invitations.sort((invitation1, invitation2) => {
          return invitation2.sentDatetime - invitation1.sentDatetime;
        });
        const distinctAccounts = Array.from(new Set(this.items.map(invitation => invitation.accountId)));
        this.accounts = ['all'].concat(distinctAccounts.sort());
      }).catch(err => {
        console.log(`Get invitations failed: ${err.message}`);
      });
    }

  adminUrl(invitation: Invitation): string {
    return `${environment.adminPanelURL}/person-details.seam?currentUserId=${invitation.verificationId || ''}`;
  }


  select(invitation: Invitation) {
    console.log(`Selected ${JSON.stringify(invitation, null, 4)}`);
    this.router.navigate(['verification', invitation.id, invitation.verificationId]);
  }

}

