import { OnInit, Component } from '@angular/core';
import { VerificationClientService } from '../../service/verification-client.service';
import { ExtendedVerificationResult } from '../../shared/model/extended-verification-result';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { environment } from '../../../environments/environment';
import { DocumentUpload } from 'src/app/shared/model/verification-result';

@Component({
  selector: 'app-verification-result',
  templateUrl: './verification-result.html',
  styleUrls: ['./verification-result.css']
})
export class VerificationResultComponent extends BioVerifyComponent implements OnInit  {

  data: ExtendedVerificationResult;
  apiKey: string;

  get isSample(): boolean {
    if (this.apiKey) {
      return environment.production === false;
    }
    return false;
  }

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private verificationService: VerificationClientService
  ) {
    super();
    console.log('in VerificationResultComponent');
  }

  ngOnInit() {
    const invitationId = this.route.snapshot.paramMap.get('invitationId');
    const verificationId = this.route.snapshot.paramMap.get('verificationId');
    this.apiKey = this.route.snapshot.queryParamMap.get('apiKey');

    if (this.apiKey) {
      this.verificationService.getVerificationWithKeyAuthorization(verificationId, invitationId, this.apiKey).then(result => {
        this.data = new ExtendedVerificationResult(result);
      }).catch(err => {
        console.log(`Get verification failed: ${err.message}`);
        this.showAlert(this.dialog, 'PROBLEM', 'The verification could not be retrieved - please try again later');
      });
    } else {
      this.verificationService.getVerificationWithUserAuthorization(verificationId, invitationId).then(result => {
        // console.log(`result: ${JSON.stringify(result, null, 4)}`);
        this.data = new ExtendedVerificationResult(result);
      }).catch(err => {
        console.log(`Get verification failed: ${err.message}`);
        this.showAlert(this.dialog, 'PROBLEM', 'The verification could not be retrieved - please try again later');
      });
    }
  }

  fullPath(documentUpload: DocumentUpload): string {
    console.log(`Get full path for ${JSON.stringify(documentUpload)}`);
    const fullImagePath =  environment.bioverifyApiURL + `/client/images/${documentUpload.path}?apiKey=${this.apiKey}`;
    return fullImagePath;
  }

}
