import {Component, OnInit, ViewChild} from '@angular/core';
import {UserLoginService} from '../../service/user-login.service';
import {LoggedInCallback} from '../../service/cognito.service';
import {Router} from '@angular/router';
import { InvitationService } from '../../service/invitation.service';
import { Invitation } from '../../shared/model/invitation';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { UserDetailsService } from '../../service/user-details.service';
import { NewInviteComponent } from '../new-invite/new-invite.component';
import { environment } from '../../../environments/environment';
import { UserDetails } from '../../shared/model/user-details';
import { ExtendedInvitation } from '../../shared/model/extended-invitation';

const ELEMENT_DATA: ExtendedInvitation[] = [];

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.html',
  styleUrls: ['./verifications.css']
})
export class VerificationsComponent  extends BioVerifyComponent implements LoggedInCallback, OnInit {
  displayedColumns: string[] = ['sentDatetime', 'fullNameWithReference', 'clientNameWithReference', 'verificationId'];
  footerColumns = ['sentDatetime'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  userDetails: UserDetails;

  get footerMessage(): string {
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length > 0) {
      return `Total verifications: ${this.dataSource.data.length}`;
    }
    return 'No verifications have been created.';
  }
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    public invitationService: InvitationService,
    public userService: UserLoginService,
    public userDetailsService: UserDetailsService
  ) {
    super();
    this.userService.isAuthenticated(this);
    console.log('in VerificationsComponent');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      console.log('Checking user type');
      this.userDetailsService.getUserDetails().then(userDetails => {
        console.log(`getUserDetails returned: ${JSON.stringify(userDetails)}`);
        const usersHomePath = this.usersHomePath(userDetails);
        if (usersHomePath === this.router.url) {
          this.userDetails = userDetails;
          this.loadInvitations();
        } else {
          console.log(`Navigating to ${usersHomePath}`);
          this.router.navigate([usersHomePath]);
        }
      }).catch(err => {
        console.log(`getUserDetails failed: ${err.message}`);
        this.showAlert(this.dialog, 'PROBLEM', 'Oops, something is not right - please try again later');
        this.router.navigate(['/home/login']);
      });
    }
  }


  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadInvitations() {
    this.showProgressSpinner(this.dialog);
    this.invitationService.get().then(invitations => {
      const items = invitations.sort((invitation1, invitation2) => {
        return invitation2.sentDatetime - invitation1.sentDatetime;
      });
      this.dataSource.data = items.map(invitation => new ExtendedInvitation(invitation));
      this.hideProgressSpinner();
    }).catch(err => {
      this.hideProgressSpinner();
      console.log(`Get invitations failed: ${err.message}`);
      this.showAlert(this.dialog, 'PROBLEM', 'Invitations could not be retrieved - please try later.');
    });

  }

  reportUrl(invitation: ExtendedInvitation): string {
      return `${environment.adminPanelURL}/person-details.seam?currentUserId=${invitation.verificationId || ''}`;
  }

  verificationId(invitation: ExtendedInvitation): string {
    if (invitation.verificationId) {
      return `${invitation.isBiometric ? 'B-' : 'S-'}${invitation.verificationId}`;
    }
    return '';
  }

}

