import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddressService, Country } from '../../service/address.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Address, AddressSuggestion } from '../../shared/model/address';
import { BrandingService } from '../../service/branding.service';
import { VerificationClientService } from 'src/app/service/verification-client.service';
import { DOCUMENT } from '@angular/common';
import { VerificationType } from '../../shared/model/invitation';

@Component({
  selector: 'app-address-capture',
  templateUrl: './address-capture.component.html',
  styleUrls: ['./address-capture.component.css']
})
export class AddressCaptureComponent extends BioVerifyComponent implements OnInit {

  addressForm: FormGroup;
  addressSuggestions: AddressSuggestion[] = [];
  selectedCountryCode: string;
  autoCompletionCountries: Country[];

  get logoUrl(): string {
    return this.brandingService.logoUrl;
  }

  get allCountries(): Country[] {
    return this.addressService.allCountries;
  }

  get disabled(): boolean {
    if (this.selectedCountryCode === 'OTHER') {
      const address =  this.getEnteredAddress();
      if (address && address.streetNumber && address.streetName && (address.suburb || address.city) && address.postcode && address.country) {
        return false;
      }
      return true;
    } else {
      const addressText = this.addressForm.value.fullAddress;
      const matchingSuggestion = this.addressSuggestions.find(suggestion => suggestion.fullAddress === addressText);
      if (matchingSuggestion) {
        return false;
      }
      return true;
    }
  }

  private invitationId: string;
  private isSaving = false;
  private captureUrl = '';
  private verificationType: VerificationType;

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private brandingService: BrandingService,
    private verificationClientService: VerificationClientService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit() {
    this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    this.captureUrl = this.route.snapshot.queryParamMap.get('captureUrl');
    this.verificationType = this.route.snapshot.queryParamMap.get('verificationType') as VerificationType;
    const autoCompletionCountries =  this.addressService.autoCompletionCountries;
    if (this.verificationType && this.verificationType.startsWith('UK')) {
      const gb = autoCompletionCountries.find(country => country.code === 'GB');
      this.autoCompletionCountries = [gb].concat(autoCompletionCountries.filter(country => country.code !== 'GB'));
    } else if (this.verificationType && this.verificationType.startsWith('NZ')) {
      const nz = autoCompletionCountries.find(country => country.code === 'NZ');
      this.autoCompletionCountries = [nz].concat(autoCompletionCountries.filter(country => country.code !== 'NZ'));
    } else {
      this.autoCompletionCountries = autoCompletionCountries;
    }
    this.buildForm();
  }

  buildForm(): void {
    this.addressForm = this.fb.group({
      'country' : ['', [Validators.required]],
      'fullAddress' : ['', [Validators.required]],
      'unitNumber' : ['', []],
      'streetNumber' : ['', []],
      'streetName' : ['', []],
      'suburb' : ['', []],
      'city' : ['', []],
      'postcode' : ['', []],
      'otherCountry' : ['', []],
    });

    this.addressForm.get('country')
    .valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      console.log(`Selected country ${data}`);
      this.selectedCountryCode = data;
      this.addressSuggestions = [];
    });

    this.addressForm.get('fullAddress')
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(300))
      .subscribe(data => this.updateAddressSuggestions(data as string));

  }

  public updateAddressSuggestions(text: string) {
     if (text && text.length > 4) {
      const matchingAddress = this.addressSuggestions.find (address => address.fullAddress === text);
      if (matchingAddress) {
        return;
      }
      this.addressService.addressSearch(this.invitationId, text, this.selectedCountryCode).then(addresses => {
        this.addressSuggestions = addresses;
      }, err => {
        console.log('Load addresses failed: ' + JSON.stringify(err, null, 4));
      });
    }
    this.addressSuggestions = [];
  }

  public async confirm() {
    try {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      this.showProgressSpinner(this.dialog);
      let residentialAddress: Address;
      if (this.selectedCountryCode === 'OTHER') {
        residentialAddress = this.getEnteredAddress();
      } else {
        const addressText = this.addressForm.value.fullAddress;
        const suggestion = this.addressSuggestions.find(suggestion => suggestion.fullAddress === addressText);
        if (suggestion) {
          residentialAddress = await this.addressService.addressLookUp(suggestion, this.selectedCountryCode)
        }
      }
      console.log(`Confirmed ${JSON.stringify(residentialAddress, null, 4)}`);
      const invitationId = this.route.snapshot.paramMap.get('invitationId');
      await this.verificationClientService.saveAddress(invitationId, residentialAddress);
      this.hideProgressSpinner();
      this.isSaving = false;
      if (this.verificationType === 'UK_STANDARD_BIOMETRIC') {
        this.router.navigate(['client/capture/', this.invitationId], { replaceUrl: true, queryParams: { captureUrl: this.captureUrl, verificationType: this.verificationType } });
      } else {
        this.document.location.href = this.captureUrl;
      }
    } catch(err) {
      console.log(`Error ${err.message}`);
      this.hideProgressSpinner();
      this.isSaving = false;
      this.showAlert(this.dialog, 'PROBLEM', `Failed to save address. Press try again.`);
    };
  }

  private getEnteredAddress(): Address {
    const address: Address = {
      unitNumber: this.addressForm.value.unitNumber,
      streetNumber: this.addressForm.value.streetNumber,
      streetName: this.addressForm.value.streetName,
      streetType: '',
      suburb: this.addressForm.value.suburb,
      city: this.addressForm.value.city,
      postcode: this.addressForm.value.postcode,
      country: this.addressForm.value.otherCountry,
    };
    return address;
  }
}
