// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  region: 'ap-southeast-2',

  identityPoolId: 'ap-southeast-2:0c91b4b8-190e-4b34-8d51-cbe1c071796a',
  userPoolId: 'ap-southeast-2_MB8DvShUJ',
  clientId: 'jmvvse0ghtp8a8fcgq0o53rt8',

  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  bioverifyApiURL: 'https://dev.api.bioverify.app',
  adminPanelURL: 'https://test-au.vixverify.com/admin',
  paymentKey: 'pk_test_m02kf4lNyPavS5muN8wpdwv9'
};
