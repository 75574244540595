import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { BioVerifyComponent } from 'src/app/shared/bio-verify.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-liveness',
  templateUrl: './liveness.component.html',
  styleUrls: ['./liveness.component.css']
})
export class LivenessComponent extends BioVerifyComponent implements OnInit {

  public showVideoElement = false;

  get videoUrl(): string {
    return `${environment.bioverifyApiURL}/v1/video/${this.verificationId}?invitationId=${this.invitationId}&token=${this.captchaToken}`;
  }

  get isSupportedBrowser(): boolean {
    const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
    return isSafari === false;
  }

  private verificationId = '';
  private invitationId = '';
  private captchaToken: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    this.verificationId = this.route.snapshot.paramMap.get('verificationId');
  }

  resolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
    this.showVideoElement = true;
  }

}
