import { Pipe, PipeTransform } from '@angular/core';
import { Invitation } from '../../shared/model/invitation';

@Pipe({ name: 'invitationFilter' })
export class InvitationPipe implements PipeTransform {
  transform(value: Invitation[], filterValue: string): Invitation[] {
    console.log(`Pipe value: ${filterValue}`);
    if (value === null || filterValue === null || filterValue === 'all') {
      return value;
    } else {
      return value.filter(invitation => {
        return invitation.accountId === filterValue;
      });
    }
  }
}
