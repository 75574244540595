import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SimulationService } from 'src/app/service/simulation.service';
import { BioVerifyComponent } from 'src/app/shared/bio-verify.component';
import { VerificationType } from 'src/app/shared/model/invitation';
import { SimulationChoices } from 'src/app/shared/model/simulation-choices';

@Component({
  selector: 'app-simulation-choices-capture',
  templateUrl: './simulation-choices-capture.component.html',
  styleUrls: ['./simulation-choices-capture.component.css']
})
export class SimulationChoicesCaptureComponent extends BioVerifyComponent implements OnInit {

  get selectedVerificationType(): VerificationType {
    if (this.captureForm) {
      return this.captureForm.get('verificationType').value;
    }
    return 'NZ_STANDARD_BIOMETRIC';
  }

  isSaving = false;
  captureForm: FormGroup;
  private invitationId: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private simulationService: SimulationService,
  ) {
    super();
  }

  ngOnInit() {
    this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    this.buildForm();
  }

  buildForm(): void {
    this.captureForm = this.fb.group({
      'verificationType': ['NZ_STANDARD_BIOMETRIC', [Validators.required]],
      'country': ['NZ', [Validators.required]],
      'documentType': ['DRIVERS_LICENCE.NZ', [Validators.required]],
      'faceMatchState': ['VERIFIED', [Validators.required]],
      'livenessState': ['VERIFIED', [Validators.required]],
      'documentAuthenticity': ['VERIFIED', [Validators.required]],
      'documentDataSourceMatchStatus': ['MATCH', [Validators.required]],
      'dataExtraction': ['UNCHANGED', [Validators.required]],
      'addressSourceStatus': ['MATCH', [Validators.required]],
      'watchListChecks': ['NO_MATCH', [Validators.required]],
    });

    // this.captureForm.get('sendVia')
    // .valueChanges
    // .pipe(takeUntil(this.ngUnsubscribe))
    // .subscribe(data => this.updateValidators(data as string));

  }

  submit() {
    console.log(`Submit`);
    if (this.isSaving) {
      return;
    }
    if (this.captureForm.valid) {
      this.isSaving = true;
      this.showProgressSpinner(this.dialog);
      const choices = this.captureForm.value as SimulationChoices;
      console.log(`Sending choices ${JSON.stringify(choices)}`);
      this.simulationService.submitChoices(this.invitationId, choices).then(result => {
        console.log(`Sent successfully`);
        this.hideProgressSpinner();
        this.router.navigate(['client/confirmation'], { replaceUrl: true });
      }).catch(err => {
        console.log(`Send failed: ${err.message}`);
        this.isSaving = false;
        this.hideProgressSpinner();
        this.showAlert(this.dialog, 'PROBLEM', `You simulation choices could not be saved - please try again later`);
      });
    }
  }

}
