import { Component, OnInit } from '@angular/core';
import { BrandingService } from '../../service/branding.service';

@Component({
  selector: 'app-capture-confirmation',
  templateUrl: './capture-confirmation.component.html',
  styleUrls: ['./capture-confirmation.component.css']
})
export class CaptureConfirmationComponent implements OnInit {

  get logoUrl(): string {
    return this.brandingService.logoUrl;
  }

  constructor(
    private brandingService: BrandingService,
  ) { }

  ngOnInit() {
  }

}
