import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Invitation } from '../shared/model/invitation';
import { CognitoUtil } from './cognito.service';
import { PreInvitation } from '../shared/model/pre-invitation';

@Injectable()
export class InvitationService {

  constructor(
    private http: HttpClient,
    public cognitoUtil: CognitoUtil
  ) { }

  send(invitation: Invitation): Promise<Object> {
    return this.cognitoUtil.getIdToken().then(token => {
      return token;
    }).then(token => {
      const url = environment.bioverifyApiURL + `/invite`;
      console.log('Calling ' + url);
      return this.http.post(url, invitation, { headers: {'Authorization': token }}).toPromise();
    });
  }

  get(): Promise<any> {
    return this.cognitoUtil.getIdToken().then(token => {
      return token;
    }).then(token => {
      const url = environment.bioverifyApiURL + `/invitation`;
      console.log('Calling ' + url);
      return this.http.get(url, { headers: {'Authorization': token }}).toPromise();
    });
  }

  generateLink(lastFourDigits: string): Promise<PreInvitation> {
    return this.cognitoUtil.getIdToken().then(token => {
      return token;
    }).then(token => {
      const url = environment.bioverifyApiURL + `/pre-invite`;
      console.log('Calling ' + url);
      return this.http.post(url, {forMobileNumberEnding: lastFourDigits}, { headers: {'Authorization': token }}).toPromise();
    });
  }

  redeemPreInvite(preInvitationId: string, invitation: Invitation): Promise<Object> {
    const url = environment.bioverifyApiURL + `/pre-invite/redeem`;
    console.log('Calling ' + url);
    const body = {
      invitation: invitation,
      preInvitationId: preInvitationId
    };
    return this.http.post(url, body).toPromise();
  }

  redeemInvite(invitationId: string): Promise<Object> {
    const url = environment.bioverifyApiURL + `/invitation`;
    console.log('Calling ' + url);
    const body = {
      code: invitationId,
    };
    return this.http.post(url, body).toPromise();
  }

}
