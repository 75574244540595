import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BioVerifyComponent } from '../../shared/bio-verify.component';
import { environment } from '../../../environments/environment';
import { ImageCollectionResult, ImageCollectionResultImage } from '../../shared/model/image-collection-result';
import { VerificationClientService } from '../../service/verification-client.service';
import { ExtendedVerificationResult } from '../../shared/model/extended-verification-result';

@Component({
  selector: 'app-image-collection-result',
  templateUrl: './image-collection-result.component.html',
  styleUrls: ['./image-collection-result.component.css']
})
export class ImageCollectionResultComponent extends BioVerifyComponent implements OnInit {

  apiKey: string;
  imageCollectionResult: ImageCollectionResult;
  data: ExtendedVerificationResult;

  get isSample(): boolean {
    return environment.production === false;
  }

  constructor(
    private route: ActivatedRoute,
    private verificationClientService: VerificationClientService,
  ) {
    super();
    console.log('in ImageCollectionResultComponent');
  }

  ngOnInit() {
    const invitationId = this.route.snapshot.paramMap.get('invitationId');
    const verificationId = this.route.snapshot.paramMap.get('verificationId');
    this.apiKey = this.route.snapshot.queryParamMap.get('apiKey');
    if (this.apiKey) {
      this.verificationClientService.getImageCollectionWithKeyAuthorization(verificationId, invitationId, this.apiKey).then(result => {
        this.imageCollectionResult = result;
        this.data = new ExtendedVerificationResult(result.verificationResult);
      }).catch(err => {
        console.log(`Get verification failed: ${err.message}`);
      });
    }
  }

  fullPath(capturedImage: ImageCollectionResultImage): string {
    return `${this.verificationClientService.getFullImagePath(capturedImage.path)}?apiKey=${this.apiKey}`;
  }

}
